import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import orderListReducer from '../slices/orderListSlice.js';
import orderReducer from '../slices/orderSlice.js';
import orderStatusListReducer from '../slices/orderStatusListSlice.js';
import quantityTypeListReducer from '../slices/quantityTypeSlice.js';
import articleListReducer from '../slices/articleListSlice.js';
import articleReducer from '../slices/articleSlice.js';
import carListReducer from '../slices/carListSlice.js';
import carReducer from '../slices/carSlice.js';
import clientListReducer from '../slices/clientListSlice.js';
import clientTypeListReducer from '../slices/clientTypeListSlice.js';
import clientReducer from '../slices/clientSlice.js';
import userReducer from '../slices/userSlice.js';
import userListReducer from '../slices/userListSlice.js';
import roleListReducer from '../slices/roleListSlice.js';
import roleReducer from '../slices/roleSlice.js';
import carManufacturerListReducer from '../slices/carManufacturerListSlice.js';
import carManufacturerReducer from '../slices/carManufacturerSlice.js';
import carModelListReducer from '../slices/carModelListSlice.js';
import carModelReducer from '../slices/carModelSlice.js';
import paymentTypeListReducer from '../slices/paymentTypeListSlice.js';
import partnersListReducer from '../slices/partnerListSlice.js';
import fileReducer from '../slices/fileSlice.js';

export default configureStore({
    reducer: {
        auth: authReducer,
        orderList: orderListReducer,
        order: orderReducer,
        orderStatusList: orderStatusListReducer,
        paymentTypeList: paymentTypeListReducer,
        partnerList: partnersListReducer,
        quantityTypeStatusList: quantityTypeListReducer,
        articleList: articleListReducer,
        article: articleReducer,
        carList: carListReducer,
        car: carReducer,
        carManufacturerList: carManufacturerListReducer,
        carManufacturer: carManufacturerReducer,
        carModelList: carModelListReducer,
        carModel: carModelReducer,
        clientList: clientListReducer,
        client: clientReducer,
        clientTypeList: clientTypeListReducer,
        userList: userListReducer,
        user: userReducer,
        roleList: roleListReducer,
        role: roleReducer,
        file: fileReducer
    },
})