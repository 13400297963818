import { createSlice} from '@reduxjs/toolkit';
import {deleteItem, fetchItem, uploadItem} from "../utils/sliceFunctions.js";
import {resetStateDataReducer} from "../utils/commonReducers.js";

const initialState = {
    file: null,
    loading: false,
    errors: [],
    status: 'idle'
};

export const deleteFile = deleteItem('file/delete')
export const uploadFile = uploadItem('file/upload');

const fileItem = createSlice({
    name: 'fileItem',
    initialState: initialState,
    reducers: {
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        builder.addCase(deleteFile.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        })
            .addCase(deleteFile.rejected, (state, action) => {
                state.errors = action.payload || action.error.message;
                state.loading = false;
            })
            .addCase(deleteFile.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.loading = false;
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.errors = action.payload || action.error.message;
                state.loading = false;
            })
            .addCase(uploadFile.pending, (state) => {
                state.loading = true;
            })
    },
});

export default fileItem.reducer;
export const {
    resetStateData
} = fileItem.actions;