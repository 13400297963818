import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import { changeAttributeReducer, resetStateDataReducer } from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        carModel: null,
        vinNumber: '',
        registrationNumber: '',
        firstRegisteredAt: '',
        additionalInformation: '',
        client: null
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchCar = fetchItem('car/fetchCar', '/cars')
export const saveCar = saveItem('car/saveCar', '/cars');

const carItem = createSlice({
    name: 'carItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchCar, saveCar)
    },
});

export default carItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = carItem.actions;