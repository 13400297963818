import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchArticle, resetStateData, saveArticle} from "../../../slices/articleSlice.js";
import {processIdInformation} from "../../../utils/functions.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";
import ArticleForm from "./components/ArticleForm.js";
import {setErrorsFromValidation, getValidationSchema} from "../../../utils/validation.js";
import {fetchQuantityTypes} from "../../../slices/quantityTypeSlice.js";
import ArticleNavComponent from "./components/ArticleNavComponent.js";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";

const ArticleEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: article, loading, errors: stateErrors, status} = useSelector((state) => state.article);
    const {data: quantityTypes, loading: quantityTypesLoading} = useSelector((state) => state.quantityTypeStatusList);

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const articleSchema = getValidationSchema({
        name: ['required', ['min', 2], ['max', 100]],
        stock: ['required', 'integer'],
        reservedStock: ['required', 'integer'],
        sellingPrice: ['required', 'float'],
        costPrice: ['required', 'float'],
        skuNumber: ['required'],
        manufacturer: ['required'],
        quantityType: ['object']
    });

    useEffect(() => {
        dispatch(resetStateData());
        dispatch(fetchQuantityTypes());
        if (idIsInteger) {
            dispatch(fetchArticle({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/articles', {state: {message: 'Успешно запазен артикул'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = ({target: {name, value}}) => {
        dispatchChangeAttribute(name, value);
    };

    const dispatchChangeAttribute = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setErrors({});
            await articleSchema.validate(article, {abortEarly: false});
            await dispatch(saveArticle({id: idIsInteger ? id : null, item: article}));
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const handleQuantityTypeChange = (event) => {
        const quantityTypeId = event.target.value;
        const newQuantityType = quantityTypes.find(quantityType => quantityType.id === parseInt(quantityTypeId));
        dispatchChangeAttribute('quantityType', newQuantityType);
    }

    const showForm = () => {
        return !loading && !quantityTypesLoading;
    }

    const getTitle = () => {
        return idIsInteger ? `Артикул № ${id}` : 'Нов Артикул';
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            {idIsInteger && (
                <ArticleNavComponent id={id} activeKey={'edit'}/>
            )}
            {showForm() ? (
                    <Card>
                        <Card.Body>
                            <ErrorAlertComponent error={stateErrors}/>
                            <ArticleForm
                                article={article}
                                onSubmit={handleSubmit}
                                onAttributeChange={handleAttributeChange}
                                errors={errors}
                                quantityTypes={quantityTypes}
                                onQuantityTypeChange={handleQuantityTypeChange}
                            />
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="success" type="submit" onClick={handleSubmit}>
                                Запази
                            </Button>
                        </Card.Footer>
                    </Card>
                )
                : (
                    <p>Loading</p>
                )}
        </div>
    );
};

export default ArticleEditPage;
