import React, {useRef, useState} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {uploadFile} from "../slices/fileSlice.js";
import ErrorAlertComponent from "./ErrorAlertComponent.js";
import imageCompression from 'browser-image-compression';

const MultipleFileUploadComponent = ({
    id,
    fetchItem,
    itemsPath
                                     }) => {
    const [files, setFiles] = useState([]);
    const {errors} = useSelector((state) => state.file);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);

    const handleFileChange = async (e) => {
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            const invalidFiles = filesArray.filter(file => !allowedTypes.includes(file.type));

            if (invalidFiles.length > 0) {
                alert("Неразрешен формат. Добави снимка (jpg, jpeg, png) или pdf");
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
                return;
            }

            const compressedFilesPromises = filesArray.map(async (file) => {
                if (file.type !== 'application/pdf') { // Only compress image files
                    const options = {
                        maxSizeMB: 2,  // Set max size to 1MB
                        maxWidthOrHeight: 2048,  // Max width or height
                        useWebWorker: true  // Use web worker for better performance
                    };
                    try {
                        const compressedBlob = await imageCompression(file, options);

                        // Create a new File object with the compressed Blob and retain the original name
                        return new File([compressedBlob], file.name, {
                            type: file.type,
                            lastModified: file.lastModified,
                        });
                    } catch (error) {
                        console.error("Error compressing image:", error);
                        return file;  // If compression fails, fallback to original file
                    }
                } else {
                    return file;  // If it's a PDF, skip compression
                }
            });

            // Wait for all files to be processed (compressed or left unchanged)
            const compressedFiles = await Promise.all(compressedFilesPromises);


            setFiles(compressedFiles);
        }
    };

    const handleUpload = async () => {
        if (files) {
            const formData = new FormData();
            [...files].forEach((file) => {
                formData.append('files', file);
            });

            try {
                dispatch(uploadFile({path: `/${itemsPath}/${id}/upload-files`, formData: formData}));
                setFiles([]);
                dispatch(fetchItem({id: id}))
            } catch (error) {
            }
        }
    };

    return (
        <div style={{width: '300px'}}>
            <ErrorAlertComponent error={errors} />
            <Form.Control id="file" type="file" multiple onChange={handleFileChange} className={'mb-2'}
                          ref={fileInputRef}
            />
            {files.length > 0 && (
                <>
                    <Button variant={'success'}
                            onClick={handleUpload}
                            className="submit"
                    >Добави
                    </Button>
                </>
            )}
        </div>

    );
};

export default MultipleFileUploadComponent;