import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";import React from "react";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import PhoneNumberInput from "../../../../components/Elements/PhoneNumberInput.js";

const ClientForm = ({
    client,
    onAttributeChange,
    onClientTypeChange,
    errors,
    clientTypes,
                    }) => {
    return (
        <Form>

            <Form.Group >
                <Row>
                        <Col md={2}>
                            <SelectInput
                                label={'Тип клиент'}
                                value={client.clientType ? client.clientType.id : ''}
                                name={'clientType'}
                                errors={errors}
                                onChange={onClientTypeChange}
                                selectOptions={clientTypes}
                                />
                        </Col>
                        <Col md={3}>
                            <FormInput label="ЕГН/Булстат" name="identificationId" value={client.identificationId}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>

                        <Col md={3}>
                            <FormInput label="ДДС номер" name="vatNumber" value={client.vatNumber}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>
                        <Col md={3}>
                            <FormInput label="МОЛ" name="molField" value={client.molField}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormInput label="Име" name="name" value={client.name}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>
                        <Col md={4}>
                            <FormInput label="Email" name="email" value={client.email}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>

                        <Col md={4}>
                            <PhoneNumberInput value={client.phoneNumber} name={'phoneNumber'} onChange={onAttributeChange} errors={errors} />
                        </Col>


                        <Col md={4}>
                            <FormInput label="Адрес" name="address" value={client.address}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>

                        <Col md={2}>
                            <FormInput label="Град" name="city" value={client.city}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>

                        <Col md={2}>
                            <FormInput label="Пощенски код" name="zip" value={client.zip}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>

                        <Col md={2}>
                            <FormInput label="Държава" name="country" value={client.country}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>

                        <Col md={12}>
                            <FormInput as={'textarea'} label="Допълнителна информация" name="additionalInformation" value={client.additionalInformation}
                                       onChange={onAttributeChange} errors={errors}/>
                        </Col>
                    </Row>
            </Form.Group>
        </Form>
    )
}

export default ClientForm;