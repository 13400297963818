import * as Yup from "yup";

export const getValidationSchema = (fields) => {
    const schema = {};

    Object.keys(fields).forEach((key) => {
        const fieldIsInteger = fields[key].includes('integer');
        const fieldIsFloat = fields[key].includes('float');

        let fieldSchema;

        if (fieldIsInteger) {
            fieldSchema = Yup.number().integer().typeError('Трябва да е цяло число');
        } else if (fieldIsFloat) {
            fieldSchema = Yup.number().typeError('Трябва да е число');
        } else {
            fieldSchema = Yup.string().typeError('Трябва да е текст');
        }

        fields[key].forEach((rule) => {
            if (rule === 'required') {
                fieldSchema = fieldSchema.required('Задължително поле');
            }
            if (rule === 'integer') {
                fieldSchema = fieldSchema
                    .integer('Трябва да е цяло число')
            }
            if (rule === 'email') {
                fieldSchema = fieldSchema.email('Въведи правилен email');
            }
            if (Array.isArray(rule) && rule[0] === 'max') {
                const maxLength = rule[1];
                fieldSchema = fieldSchema.max(maxLength, `Максимум ${maxLength} символа`);
            }
            if (Array.isArray(rule) && rule[0] === 'min') {
                const minLength = rule[1];
                fieldSchema = fieldSchema.min(minLength, `Минимум ${minLength} символа`);
            }

            if (Array.isArray(rule) && rule[0] === 'length') {
                const length = rule[1];
                fieldSchema = fieldSchema.length(length, `Нужни са ${length} символа`);
            }

            if (rule === 'object') {
                fieldSchema = Yup.object().required('Задължително поле');
            }
            if (rule === 'array') {
                fieldSchema = Yup.array().required('Задължително поле');
            }
        });


        schema[key] = fieldSchema;
    });

    return Yup.object().shape(schema);
};

export const setErrorsFromValidation = (errors) => {
    const validationErrors = {};
    errors.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
    });
    return validationErrors;
}
