import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import InvoicePdf from "../../../Clients/Invoice/InvoicePdf.js";

const OrderNavComponent = ({
    id,
    activeKey
                           }) => {

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'view'} to={`/orders/${id}`}>Преглед</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/orders/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'files'} to={`/orders/${id}/files`}>Файлове</Nav.Link>
            </Nav.Item>

            {false && (
            <Nav.Item>
                <div>
                    <InvoicePdf/>
                </div>
            </Nav.Item>
            )}
        </Nav>
    )
}

export default OrderNavComponent;