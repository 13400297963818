import {getValidationSchema} from "./validation.js";

export const getOrderSchema = () => getValidationSchema({
    car: ['object'],
    client: ['object'],
    additionalInformation: ['string'],
    total: ['float'],
    orderLines: ['array'],
    orderStatus: ['object'],
    partner: ['object']
})

export const getOrderLineSchema = () => getValidationSchema({
    article: ['object'],
    quantity: ['required', 'float'],
    price: ['required', 'float'],
    discount: ['integer'],
    totalPrice: ['required', 'float']
})