import React from 'react';
import { Form } from 'react-bootstrap';

function SelectInput({ label, name, value, onChange, selectOptions, errors = [], as='' }) {

    return (
        <Form.Group className={'pt-2'}>
            <Form.Label>{label}</Form.Label>
            <Form.Select value={value}
                         name={name}
                         onChange={onChange}
                         isInvalid={!!errors[name]}
            >
                <option disabled={true} value={''}>Избери</option>

                {selectOptions.map((selectOption) => (
                    <option key={selectOption.id} value={selectOption.id}>
                        {selectOption.name}
                    </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default SelectInput;
