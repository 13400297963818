import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import { changeAttributeReducer, resetStateDataReducer } from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        reservedStock: 0,
        stock: 0,
        sellingPrice: 0,
        costPrice: 0,
        manufacturer: '',
        supplier: '',
        isNew: 1,
        additionalInformation: '',
        skuNumber: '',
        availableStock: 0,
        quantityType: null
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchArticle = fetchItem('article/fetchArticle', '/articles')
export const saveArticle = saveItem('article/saveArticle', '/articles');

const articleItem = createSlice({
    name: 'articleItem',
    initialState: {
        data: initialState,
        loading: true,
        error: null
    },
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchArticle, saveArticle)
    },
});

export default articleItem.reducer;
export const {
    changeAttribute,
    resetStateData
} = articleItem.actions;