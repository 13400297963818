import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchCarModel, resetStateData, saveCarModel} from "../../../slices/carModelSlice.js";
import { processIdInformation} from "../../../utils/functions.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";
import {fetchCarManufacturers} from "../../../slices/carManufacturerListSlice.js";
import CarModelForm from "./Components/CarModelForm.js";
import {setErrorsFromValidation, getValidationSchema} from "../../../utils/validation.js";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";

const CarModelPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: carModel, loading, errors: stateErrors, status} = useSelector((state) => state.carModel)
    const {data: carManufacturers, loading: carManufacturersLoading} = useSelector((state) => state.carManufacturerList)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const carModelSchema = getValidationSchema({
        name: ['required', ['max', 50], ['min', 2]],
        synonyms: [['max', 255]],
        carManufacturer: ['required', 'object']
    })

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCarModel({id: id}));
        }
        dispatch(fetchCarManufacturers({}));
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/car-models',  { state: { message: 'Успешно запазен модел' } });
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (event) => {
        const attribute = event.target.name;
        const value = event.target.value;
        dispatchChangeAttribute(attribute, value);
    };

    const dispatchChangeAttribute = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    }

    const handleCarManufacturerChange = (event) => {
        const carManufacturerId = event.target.value;
        const newCarManufacturer = carManufacturers.find(carManufacturer => carManufacturer.id === parseInt(carManufacturerId));
        dispatchChangeAttribute('carManufacturer', newCarManufacturer);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setErrors({});
            await carModelSchema.validate(carModel, {abortEarly: false});
            await dispatch(saveCarModel({id: idIsInteger ? id : null, item: carModel}));
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const showForm = () => {
        return !loading && !carManufacturersLoading;
    }

    const getTitle = () => {
        return idIsInteger ? `Модел № ${id}` : 'Нов Модел';
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            {showForm() ? (
                <Card>
                    <Card.Body>
                        <ErrorAlertComponent error={stateErrors}/>
                        <CarModelForm
                            carModel={carModel}
                            onAttributeChange={handleAttributeChange}
                            onCarManufacturerChange={handleCarManufacturerChange}
                            carManufacturers={carManufacturers}
                            errors={errors}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="success" type="submit" onClick={handleSubmit}>
                            Запази
                        </Button>
                    </Card.Footer>
                </Card>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default CarModelPage;
