import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: null
}
export const fetchCarManufacturers = fetchItems('carManufacturers/fetchCarManufacturers', '/car-manufacturers');

const carManufacturerListSlice = createSlice({
    name: 'carManufacturers',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchCarManufacturers)
    },
});

export const {
    setCurrentPage
} = carManufacturerListSlice.actions;


export default carManufacturerListSlice.reducer;