import {Routes, Route} from "react-router-dom";
import CarListPage from "../pages/Cars/List/CarListPage.js";
import CarPage from "../pages/Cars/Single/CarPage.js";
import CarModelListPage from "../pages/CarModels/List/CarModelListPage.js";
import CarModelPage from "../pages/CarModels/Single/CarModelPage.js";
import CarManufacturerListPage from "../pages/CarManufacturers/List/CarManufacturerListPage.js";
import CarManufacturerPage from "../pages/CarManufacturers/Single/CarManufacturerPage.js";

const CarRoutes = () => (
    <Routes>
        <Route index element={<CarListPage />} />
        <Route path=':id' element={<CarPage />} />
        <Route path='/models' element={<CarModelListPage />} />
        <Route path='/models/:id' element={<CarModelPage />} />
        <Route path='manufacturers' element={<CarManufacturerListPage />} />
        <Route path='manufacturers/:id' element={<CarManufacturerPage />} />
    </Routes>
);

export default CarRoutes;