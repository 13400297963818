import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React, {useEffect} from "react";
import {getArticleAvailableStock} from "../../../../slices/orderSlice.js";
import {useDispatch} from "react-redux";
import {useState} from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchArticles} from "../../../../slices/articleListSlice.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import {toFloat} from "../../../../utils/functions.js";
import {getOrderLineSchema} from "../../../../utils/schemas.js";
import {setErrorsFromValidation} from "../../../../utils/validation.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Table} from "react-bootstrap";

const OrderLineModalComponent = ({
    order,
    show,
    orderLine = null,
    closeOrderLineModal,
    onLineUpdate
}) => {
    const dispatch = useDispatch();
    const [editOrderLine, setEditOrderLine] = useState(null);
    const [orderLineType, setOrderLineType] = useState('');
    const [availableStock, setAvailableStock] = useState(0);
    const [errors, setErrors] = useState({});
    console.log(orderLineType)
    useEffect(() => {
        setEditOrderLine(orderLine)
    }, [orderLine])

    const handleClose = () => {
        closeOrderLineModal();
    };

    const changeAttribute = (attribute, value) => {
        setEditOrderLine((prevOrderLine) => ({
            ...prevOrderLine,
            [attribute]: value,
        }));

    };

    const handleLineUpdate = async () => {
        try {
            setErrors({});
            await getOrderLineSchema().validate(editOrderLine, {abortEarly: false});
            onLineUpdate(editOrderLine);
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const handleArticleChange = async (article) => {
        changeAttribute('article', article);
        changeAttribute('price', article.sellingPrice);
        setAvailableStock(getArticleAvailableStock(order, article));
        changeAttribute('quantity', 1);
        changeAttribute('discount', 0);
        changeAttribute('totalPrice', article.sellingPrice)
    }

    const handlePriceRelatedChange = ({target: {name, value}}) => {
        if (editOrderLine.article) {
            handlePriceChange(name, value);
        }
        changeAttribute(name, value);
    }

    const handlePriceChange = (changedAttribute, value) => {
        const articlePrice = editOrderLine.article.sellingPrice;
        const quantity = changedAttribute === 'quantity' ? value : editOrderLine.quantity;
        const discount = changedAttribute === 'discount' ? value : editOrderLine.discount;
        changeAttribute('totalPrice',  articlePrice * quantity * ((100 - discount) / 100))
    }

    return (
        <Modal show={show} onHide={handleClose} animation={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Позиция</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {editOrderLine && (
                    <Form>
                        <Form.Group className={'mb-3'}>
                            <SelectInput
                                name={'orderLineType'}
                                label={'Тип позиция'}
                                onChange={(e) => {setOrderLineType(e.target.value)}}
                                selectOptions={[{id: 1, name: 'Монтьорно'}, {id: 2, name: 'Боя'}]}
                                value={orderLineType}
                            />
                        </Form.Group>
                        {orderLineType == 1 ?
                        <Form.Group className="mb-3" controlId="formHorizontalEmail">
                            { isNaN(editOrderLine.id)  ? (
                                <SearchAutoCompleteComponent
                                onItemChange={handleArticleChange}
                                item={editOrderLine.article}
                                name={'article'}
                                label={'Артикул'}
                                fetchItems={fetchArticles}
                                reducer={'articleList'}
                                errors={errors}
                                />
                            ) : (
                                <h4>{editOrderLine.article.name}</h4>
                            )}
                            <Form.Label column sm={2}>Цена на брой: {toFloat(editOrderLine.price)}</Form.Label>
                            <br/>
                            <Form.Label column sm={2}>Цена позиция: {toFloat(editOrderLine.totalPrice)}</Form.Label>
                            { editOrderLine.article && (
                                <>
                                <FormNumericInput
                                value={editOrderLine.quantity}
                                name={'quantity'}
                                errors={errors}
                                onChange={handlePriceRelatedChange}
                                label={'Количество'}
                                min={1}
                                max={availableStock}
                            />
                            <FormNumericInput
                                value={editOrderLine.discount}
                                name={'discount'}
                                errors={errors}
                                onChange={handlePriceRelatedChange}
                                label={'Отстъпка %'}
                                min={0}
                                max={100}
                            />
                                </>

                            )}
                        </Form.Group>
                            : orderLineType == 2 ?
                            <Form.Group>
                                <FormInput label={'Детайл'} value={'Предна броня'} />
                                <FormInput label={'Услуга'} value={'Боя'} />
                                <h6>Стъпки</h6>
                                <Table bordered>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Количество</th>
                                        <th>Мярка</th>
                                        <th>Цена</th>
                                        <th>Цена общо</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Стъпка 1</td>
                                        <td><FormNumericInput value={1.5}/></td>
                                        <td>час</td>
                                        <td><FormNumericInput value={20}/></td>
                                        <td>30  лв.</td>
                                    </tr>
                                    <tr>
                                        <td>Стъпка 2</td>
                                        <td><FormNumericInput value={3}/></td>
                                        <td>бр</td>
                                        <td><FormNumericInput value={61.25}/></td>
                                        <td>183.75  лв.</td>
                                    </tr>
                                    <tr>
                                        <td>Стъпка 3</td>
                                        <td><FormNumericInput value={10}/></td>
                                        <td>час</td>
                                        <td><FormNumericInput value={12}/></td>
                                        <td>120  лв.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}></td>
                                        <td>Общо: 333.75 лв.</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Form.Group>
                                : <></>
                        }
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>Откажи</Button>
                {editOrderLine && editOrderLine.article &&
                    <Button variant="success" onClick={handleLineUpdate}>Запази</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default OrderLineModalComponent;