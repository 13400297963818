import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchCar, resetStateData, saveCar} from "../../../slices/carSlice.js";
import { processIdInformation} from "../../../utils/functions.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";
import CarForm from "./components/CarForm.js";
import {setErrorsFromValidation, getValidationSchema} from "../../../utils/validation.js";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";

const CarPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: car, loading, errors: stateErrors, status} = useSelector((state) => state.car)

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const carSchema = getValidationSchema({
        carModel: ['required', 'object'],
        registrationNumber: ['required'],
        client: ['required', 'object']
    });

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCar({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/cars', {state: {message: 'Успешно запазена кола'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = ({target: {name, value}}) => {
        dispatchChangeAttribute(name, value);
    };

    const dispatchChangeAttribute = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setErrors({});
            await carSchema.validate(car, {abortEarly: false});
            await dispatch(saveCar({id: idIsInteger ? id : null, item: car}));
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const handleClientChange = (client) => {
        dispatchChangeAttribute('client', client)
    }

    const handleCarModelChange = (carModel) => {
        dispatchChangeAttribute('carModel', carModel)
    }

    const handleDateValueChange = (attribute, value) => {
        const [day, month, year] = value.split('.');
        const newValue= `${year}-${month}-${day}`;
        dispatchChangeAttribute(attribute, newValue);
    }

    const showForm = () => {
        return !loading;
    }

    const getTitle = () => {
        return idIsInteger ? `Кола № ${id}` : 'Нова Кола';
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            {showForm() ? (
                <Card>
                    <Card.Body>
                        <ErrorAlertComponent error={stateErrors}/>
                        <CarForm
                            car={car}
                            onSubmit={handleSubmit}
                            onAttributeChange={handleAttributeChange}
                            errors={errors}
                            onClientChange={handleClientChange}
                            onCarModelChange={handleCarModelChange}
                            onDateValueChange={handleDateValueChange}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="success" type="submit" onClick={handleSubmit}>
                            Запази
                        </Button>
                    </Card.Footer>
                </Card>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default CarPage;
