import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect} from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchCars} from "../../../../slices/carListSlice.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {fetchClients} from "../../../../slices/clientListSlice.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import DateInput from "../../../../components/Elements/DateInput.js";

const OrderForm = ({
                       order,
                       errors,
                       orderStatuses,
                       paymentTypes,
                       onAttributeChange,
                       onCarChange,
                       onInvoiceClientChange,
                       onOrderStatusChange,
                       onPaymentTypeChange,
                       onDateValueChange,
    onPartnerChange,
    partners
                   }) => {

    useEffect(() => {
        if (!order.partner) {
            onPartnerChange({target: {value: 1}})
        }
    }, [onPartnerChange, order.partner, partners]);

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col sm={4}>
                        <SearchAutoCompleteComponent
                            onItemChange={onCarChange}
                            item={order.car}
                            name={'car'}
                            label={'Контрагент/Автомобил'}
                            fetchItems={fetchCars}
                            reducer={'carList'}
                            errors={errors}
                            itemLabel={'searchLabel'}
                        />
                        <FormNumericInput name={'mileage'} label={'Пробег'} errors={errors} onChange={onAttributeChange}
                                          value={order.mileage} step={1} min={0}
                        />
                    </Col>
                    <Col sm={4}>
                        <SearchAutoCompleteComponent
                            onItemChange={onInvoiceClientChange}
                            item={order.invoiceClient}
                            name={'invoiceClient'}
                            label={'Клиент фактура'}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                            itemLabel={'searchLabel'}
                        />
                        <FormNumericInput name={'insurerClaimId'} label={'Номер на щета'} errors={errors} onChange={onAttributeChange}
                                          value={order.insurerClaimId} step={1} min={0}
                        />
                    </Col>
                    <Col sm={4}>
                        <SelectInput
                            label={'Изпълнител'}
                            value={order.partner ? order.partner.id : ''}
                            name={'partner'}
                            errors={errors}
                            onChange={onPartnerChange}
                            selectOptions={partners}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <SelectInput
                            label={'Статус'}
                            value={order.orderStatus ? order.orderStatus.id : ''}
                            name={'orderStatus'}
                            errors={errors}
                            onChange={onOrderStatusChange}
                            selectOptions={orderStatuses}
                        />
                    </Col>
                        <Col md={3}>
                            <DateInput label={'Дата приемане'} dateValue={order.startDate} name={'startDate'}
                                       onDateValueChange={onDateValueChange} errors={errors} />
                        </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <SelectInput
                            label={'Начин плащане'}
                            value={order.paymentType ? order.paymentType.id : ''}
                            name={'paymentType'}
                            errors={errors}
                            onChange={onPaymentTypeChange}
                            selectOptions={paymentTypes}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <FormInput errors={errors} onChange={onAttributeChange} name={'additionalInformation'}
                                   value={order.additionalInformation}
                                   label={'Бележка'} as={'textarea'}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </Form>

    )
}

export default OrderForm;