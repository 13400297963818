import React from 'react';
import { Form } from 'react-bootstrap';

const FormCheckbox = ({
    label,
    isChecked,
    onCheckboxChange,
    name
                      }) => {

    const handleCheckboxChange = ({target: {name}}) => {
        onCheckboxChange({target: {name: name, value: !isChecked}})
    }

    return (
        <Form.Group>
            <Form.Check
                name={name}
                type="checkbox"
                label={label}
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
        </Form.Group>
    );
};

export default FormCheckbox;
