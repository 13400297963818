import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import {toFloatBgn} from "../../../../utils/functions.js";

const OrderListTable= ({orders}) => {

    return (
    <table className={"table table-striped"}>
        <thead>
        <tr>
            <th>№</th>
            <th>Клиент</th>
            <th>Рег. номер кола</th>
            <th>Статус</th>
            <th>Стойност</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {orders.map((order) => (
            <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.client.name}</td>
                <td>{order.car.registrationNumber}</td>
                <td>{order.orderStatus.name}</td>
                <td>{toFloatBgn(order.total)}</td>
                <td>
                    <Button variant="link">
                        <Link to={`/orders/${order.id}`}>Преглед</Link>
                    </Button>
                </td>
            </tr>
        ))}
        </tbody>
    </table>
    )
}

export default OrderListTable;