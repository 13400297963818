import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClients} from "../../../../slices/clientListSlice.js";
import {fetchCarModels} from "../../../../slices/carModelListSlice.js";
import DateInput from "../../../../components/Elements/DateInput.js";

const CarForm = ({
                     car,
                     onAttributeChange,
                     errors,
                     onClientChange,
                     onCarModelChange,
    onDateValueChange
                 }) => {

    return (
        <Form>

            <Form.Group>
                <Row>
                    <Col md={3}>
                        <SearchAutoCompleteComponent
                            onItemChange={onClientChange}
                            item={car.client}
                            name={'client'}
                            label={'Клиент'}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                        />
                    </Col>
                    <Col md={3}>
                        <FormInput label="Рег. номер"
                                   name="registrationNumber"
                                   value={car.registrationNumber}
                                   onChange={onAttributeChange}
                                   errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <SearchAutoCompleteComponent
                            onItemChange={onCarModelChange}
                            item={car.carModel}
                            name={'carModel'}
                            label={'Марка/Модел'}
                            fetchItems={fetchCarModels}
                            reducer={'carModelList'}
                            errors={errors}
                            itemLabel={'modelManufacturerName'}
                        />
                    </Col>
                    <Col md={3}>
                        <FormInput label="ВИН"
                                   name="vinNumber"
                                   value={car.vinNumber}
                                   onChange={onAttributeChange}
                                   errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <DateInput label={'Първа регистрация'} dateValue={car.firstRegisteredAt} name={'firstRegisteredAt'}
                                   onDateValueChange={onDateValueChange} errors={errors} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormInput as={'textarea'} label="Допълнителна информация" name="additionalInformation"
                                   value={car.additionalInformation}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
};

export default CarForm;