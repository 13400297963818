
export const processIdInformation = (id) => {
    const idIsInteger = /^\d+$/.test(id);
    const isValidId = id === 'create' || idIsInteger;

    return {
        idIsInteger: idIsInteger,
        isValidId: isValidId
    };
}

export const calculateOrderTotalPrice = (orderLines) => {
    return orderLines
        .filter(item => item.isActive)
        .reduce((acc, item) => acc + parseFloat(item.totalPrice), 0);
};

export const toFloat = (number) => {
    return number.toFixed(2)
}

export const toFloatBgn = (number) => {
    return number.toFixed(2) + ' лв.'
}

export const getVatFloatBgn = (priceWithVat) => {
    return toFloatBgn(priceWithVat / 6);
}