import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrder,
    resetStateData,
} from "../../../slices/orderSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation, toFloatBgn} from "../../../utils/functions.js";
import {Card} from "react-bootstrap";
import OrderNavComponent from "./components/OrderNavComponent.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateToBeautifulString} from "../../../utils/dateFunctions.js";
import OrderLinesTableComponent from "./components/OrderLinesTableComponent.js";

const OrderViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: order, loading} = useSelector((state) => state.order);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchOrder({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading && order.id;
    }

    const getTitle = () => {
        return idIsInteger ? `Поръчка № ${id}` : 'Нова поръчка';
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            <OrderNavComponent id={id} activeKey={'view'}/>
            {showPage() ? (
                <div>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <div className={'p-2 border-dark border-1 border d-inline-block'}>
                                        <div><b>Номер:</b> {order.id}</div>
                                        <div><b>Дата:</b> {formatDateToBeautifulString(order.createdAt)}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className={'mt-3'}>
                                    <div className={'p-2 border-dark border-1 border d-inline-block'}>
                                        <div><b>Получател:</b> {order.client.name}</div>
                                        <div><b>ЕИГ/ЕНГ:</b> {order.client.identificationId}</div>
                                        <div><b>ДДС:</b> TODO</div>
                                        <div><b>Град:</b> {order.client.city}</div>
                                        <div><b>Адрес:</b> {order.client.adddress}</div>
                                        <div><b>МОЛ:</b> {order.client.molFied}</div>
                                        <div><b>Телефон:</b> {order.client.phoneNumber}</div>
                                        <div><b>Email:</b> {order.client.email}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'mt-3'}>
                                <OrderLinesTableComponent lines={order.orderLines} showingActiveLines={true} viewOnly={true} />

                                <div>
                                    <div><b>Данъчна основа:</b> {toFloatBgn(order.totalWithoutVat)}</div>
                                    <div><b>ДДС:</b> {toFloatBgn(order.vatOfTotal)}</div>
                                    <div><b>Сума за плащане:</b> {toFloatBgn(order.total)}</div>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default OrderViewPage;
