import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../../css/DateInput.css';

const  DateInput = ({
    label,
    dateValue,
    onDateValueChange,
    name,
    errors
                    }) => {
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const monthRef = useRef(null);
    const yearRef = useRef(null);

    useEffect(() => {
        if (dateValue) {
            // Parse the ISO 8601 date string
            const date = new Date(dateValue);

            // Extract the day, month (remember to add 1 because getMonth() is zero-indexed), and year
            const parsedDay = String(date.getDate()).padStart(2, '0');  // Ensure two digits for day
            const parsedMonth = String(date.getMonth() + 1).padStart(2, '0');  // Ensure two digits for month
            const parsedYear = String(date.getFullYear());

            // Set the day, month, and year states
            setDay(parsedDay || '');
            setMonth(parsedMonth || '');
            setYear(parsedYear || '');
        }
    }, [dateValue]);

    useEffect(() => {
        if (day.length === 2 && month.length === 2 && year.length === 4) {
            onDateValueChange(name, `${day}.${month}.${year}`);
        }
    }, [day, month, year, onDateValueChange, name]);

    // Handle change for the day input
    const handleDayChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Only allow digits
        if (value.length <= 2) {
            setDay(value);
            if (value.length === 2) {
                monthRef.current.focus(); // Move to month input when day is complete
            }
        }
    };

    // Handle change for the month input
    const handleMonthChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Only allow digits
        if (value.length <= 2) {
            setMonth(value);
            if (value.length === 2) {
                yearRef.current.focus(); // Move to year input when month is complete
            }
        }
    };

    // Handle change for the year input
    const handleYearChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Only allow digits
        if (value.length <= 4) {
            setYear(value);
        }
    };

    return (
        <>
            <Form.Label>{label}</Form.Label>

        <Form.Group className="d-flex align-items-center mb-2">

            <Form.Control
                type="text"
                value={day}
                onChange={handleDayChange}
                placeholder="dd"
                maxLength={2}
                className="date-part-input"
            />
            <span className="dot-separator">.</span>
            <Form.Control
                type="text"
                ref={monthRef}
                value={month}
                onChange={handleMonthChange}
                placeholder="mm"
                maxLength={2}
                className="date-part-input"
            />
            <span className="dot-separator">.</span>
            <Form.Control
                type="text"
                ref={yearRef}
                value={year}
                onChange={handleYearChange}
                placeholder="yyyy"
                maxLength={4}
                className="date-part-input year-input"
            />
            <span>г.</span>
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
        </>
    );
}

export default DateInput;
