import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchCarManufacturer, resetStateData, saveCarManufacturer} from "../../../slices/carManufacturerSlice.js";
import { processIdInformation} from "../../../utils/functions.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";
import CarManufacturerForm from "./components/CarManufacturerForm.js";
import {setErrorsFromValidation, getValidationSchema} from "../../../utils/validation.js";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";

const CarManufacturerPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: carManufacturer, loading, errors: stateErrors, status} = useSelector((state) => state.carManufacturer);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const carManufacturerSchema = getValidationSchema({
        name: ['required', ['max', 50], ['min', 2]],
        synonyms: [['max', 255]]
    })

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCarManufacturer({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/car-manufacturers',  { state: { message: 'Успешно запазен произовдител' } });
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (event) => {
        const attribute = event.target.name;
        const value = event.target.value;
        dispatchChangeAttribute(attribute, value);
    };

    const dispatchChangeAttribute = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrors({});
            await carManufacturerSchema.validate(carManufacturer, {abortEarly: false});
            await dispatch(saveCarManufacturer({id: idIsInteger ? id : null, item: carManufacturer}));
        } catch (errors) {
            setErrors(setErrorsFromValidation(errors))
        }
    };

    const showForm = () => {
        return !loading;
    }

    const getTitle = () => {
        return idIsInteger ? `Производител № ${id}` : 'Нов производител';
    }

    return (
            <div>
                <h2>{getTitle()}</h2>
                    {showForm() ? (
                        <Card>
                            <Card.Body>
                                <ErrorAlertComponent error={stateErrors}/>
                                <CarManufacturerForm
                                    carManufacturer={carManufacturer}
                                    errors={errors}
                                    onAttributeChange={handleAttributeChange}
                                />
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="success" type="submit" onClick={handleSubmit}>
                                    Запази
                                </Button>
                            </Card.Footer>
                        </Card>
                    ) : (
                        <p>Loading</p>
                    )}
                </div>
    );
};

export default CarManufacturerPage;
