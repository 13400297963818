import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchRoles} from "../../../slices/roleListSlice.js";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hasPermission} from "../../../utils/permissions.js";

const RoleListPage = () => {
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.roleList.data);
    const status = useSelector((state) => state.roleList.status);
    const role = useSelector((state) => state.auth.role);

    useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);

    return (
        <div>
            <h2>Роли</h2>
            <table className={"table table-striped"}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Име</th>
                    <th>E-Mail</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {roles.map((role) => (
                    <tr key={role.id}>
                        <td>{role.id}</td>
                        <td>{role.name}</td>
                        <td>{role.email}</td>
                        <td>
                            <Button variant="link">
                                <Link to={`/users/roles/${role.id}`}>Редактирай</Link>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default RoleListPage;
