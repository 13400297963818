import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import React from "react";

const   CarModelForm = ({
    carModel,
    carManufacturers,
    onCarManufacturerChange,
    onAttributeChange,
    errors
                      }) => {

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={3}>
                        <FormInput label="Име" name="name" value={carModel.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                    <SelectInput
                        label={'Производители'}
                        value={carModel.carManufacturer ? carModel.carManufacturer.id : ''}
                        name={'carManufacturer'}
                        errors={errors}
                        onChange={onCarManufacturerChange}
                        selectOptions={carManufacturers}
                    />
                </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormInput label="Синоними" name="synonyms"
                                   value={carModel.synonyms}
                                   onChange={onAttributeChange} errors={errors}
                                   as={'textarea'}/>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
};

export default CarModelForm;