import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCars} from "../../../slices/carListSlice.js";
import {Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import ButtonLink from "../../../components/Elements/ButtonLink.js";
import SearchQueryInputComponent from "../../../components/SearchQueryInputComponent.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import {setCurrentPage} from "../../../slices/articleListSlice.js";
import {formatDate} from "../../../utils/dateFunctions.js";
import SuccessMessageComponent from "../../../components/SuccessMessageComponent.js";

const CarListPage = () => {
    const dispatch = useDispatch();
    const {data: cars, loading, pagination} = useSelector((state) => state.carList);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(fetchCars({page: pagination.currentPage, search: query}));
    }, [dispatch, pagination.currentPage, query]);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };
    return (
        <div>
            <SuccessMessageComponent />
            <h2>Коли</h2>
            <div className="d-flex justify-content-end">
                <ButtonLink to={'/cars/create'}>Добави</ButtonLink>
            </div>
            <div>
                <SearchQueryInputComponent onQueryChange={setQuery}/>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Клиент</th>
                    <th>Рег. номер</th>
                    <th>Марка/Модел</th>
                    <th>ВИН</th>
                    <th>Първа регистратия</th>
                    <th></th>
                </tr>
                </thead>
                {!loading && (

                    <tbody>
                    {cars.map((car) => (
                        <tr key={car.id}>
                            <td>{car.id}</td>
                            <td>{car.client.name}</td>
                            <td>{car.registrationNumber}</td>
                            <td>{car.carModel.modelManufacturerName}</td>
                            <td>{car.vinNumber}</td>
                            <td>{formatDate(car.firstRegisteredAt)}</td>
                            <td>
                                <Button variant="link">
                                    <Link to={`/cars/${car.id}`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                )}

            </Table>

            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </div>
    );
};

export default CarListPage;
