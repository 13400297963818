import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchUsers} from "../../../slices/userListSlice.js";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hasPermission} from "../../../utils/permissions.js";

const UserListPage = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.userList.data);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    return (
        <div>
            <h2>Потребители</h2>
            <table className={"table table-striped"}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Име</th>
                    <th>E-Mail</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {users.map((user) => (
                    <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>
                            <Button variant="link">
                                <Link to={`/users/${user.id}`}>Редактирай</Link>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserListPage;
