import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrder,
    saveOrder,
    changeAttribute,
    updateOrderLine,
    addOrderLine,
    resetStateData,
    lineExists
} from "../../../slices/orderSlice.js";
import {fetchOrderStatuses} from "../../../slices/orderStatusListSlice.js";
import {useParams, useNavigate} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import {setErrorsFromValidation} from "../../../utils/validation.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";
import OrderForm from "./components/OrderForm.js";
import OrderLinesComponent from "./components/OrderLinesComponent.js";
import {getOrderSchema} from "../../../utils/schemas.js";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";
import OrderNavComponent from "./components/OrderNavComponent.js";
import {fetchPaymentTypes} from "../../../slices/paymentTypeListSlice.js";
import {fetchPartners} from "../../../slices/partnerListSlice.js";

const OrderEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data: order, loading, errors: stateErrors, status} = useSelector((state) => state.order);
    const {data: orderStatuses, loading: orderStatusesLoading} = useSelector((state) => state.orderStatusList)
    const {data: paymentTypes, loading: paymentTypesLoading} = useSelector((state) => state.paymentTypeList)
    const {data: partners, loading: partnersLoading} = useSelector((state) => state.partnerList)

    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(resetStateData());
        dispatch(fetchOrderStatuses());
        dispatch(fetchPaymentTypes());
        dispatch(fetchPartners());
        if (idIsInteger) {
            dispatch(fetchOrder({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/orders', {state: {message: 'Успешно запазена поръчка!'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);


    const handleAttributeChange = ({target: {name, value}}) => {
        dispatchChangeAttribute(name, value);
    };

    const dispatchChangeAttribute = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    }

    const handleOrderStatusChange = (e) => {
        const newOrderStatus = orderStatuses.find(orderStatus => orderStatus.id === parseInt(e.target.value));
        dispatchChangeAttribute('orderStatus', newOrderStatus);
    }

    const handlePaymentTypeChange = (e) => {
        const newPaymentType = paymentTypes.find(paymentType => paymentType.id === parseInt(e.target.value));
        dispatchChangeAttribute('paymentType', newPaymentType);
    }

    const handlePartnerChange = (e) => {
        const newPartner = partners.find(partner => partner.id === parseInt(e.target.value));
        dispatchChangeAttribute('partner', newPartner);
    }

    const handleCarChange = (car) => {
        dispatchChangeAttribute('car', car);
        dispatchChangeAttribute('client', car.client);
        if (order.invoiceClient === null) {
            dispatchChangeAttribute('invoiceClient', car.client);
        }
    };

    const handleInvoiceClientChange = (client) => {
        dispatchChangeAttribute('invoiceClient', client);
    };

    const handleLineUpdate = (orderLine) => {
        if (isNaN(orderLine.id) && !lineExists(order, orderLine)) {
            dispatch(addOrderLine(orderLine))
        } else {
            dispatch(updateOrderLine(orderLine));
        }
    };

    const handleDateValueChange = (attribute, value) => {
        const [day, month, year] = value.split('.');
        const newValue= `${year}-${month}-${day}`;
        dispatchChangeAttribute(attribute, newValue);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrors({});
            await getOrderSchema().validate(order, {abortEarly: false});
            await dispatch(saveOrder({id: idIsInteger ? id : null, item: order}));
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const showForm = () => {
        return !loading && !orderStatusesLoading && !paymentTypesLoading && !partnersLoading;
    }

    const getTitle = () => {
        return idIsInteger ? `Поръчка № ${id}` : 'Нова поръчка';
    }

    return (

        <div>
            <h2>{getTitle()}</h2>
            {idIsInteger && (
                <OrderNavComponent id={id} activeKey={'edit'}/>
            )}
            {showForm() ? (
                <Card>
                    <Card.Body>
                        <ErrorAlertComponent error={stateErrors}/>
                        <OrderForm
                            order={order}
                            errors={errors}
                            orderStatuses={orderStatuses}
                            paymentTypes={paymentTypes}
                            partners={partners}
                            onAttributeChange={handleAttributeChange}
                            onCarChange={handleCarChange}
                            onInvoiceClientChange={handleInvoiceClientChange}
                            onOrderStatusChange={handleOrderStatusChange}
                            onPaymentTypeChange={handlePaymentTypeChange}
                            onPartnerChange={handlePartnerChange}
                            onDateValueChange={handleDateValueChange}
                        />
                            <OrderLinesComponent
                            order={order}
                            onLineUpdate={handleLineUpdate}
                            errors={errors}
                        />
                    </Card.Body>
                    <Card.Footer>

                        <Button variant="success" onClick={handleSubmit}>
                            Запази поръчка
                        </Button>
                    </Card.Footer>
                </Card>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default OrderEditPage;
