import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

const ArticleNavComponent = ({
                               id,
                               activeKey
                           }) => {

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'view'} to={`/articles/${id}`}>Преглед</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/articles/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'files'} to={`/articles/${id}/files`}>Файлове</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default ArticleNavComponent;