import Button from "react-bootstrap/Button";
import React from "react";
import {toFloatBgn} from "../../../../utils/functions.js";
import {Table} from "react-bootstrap";

const OrderLinesTableComponent = ({
                                     lines,
                                     showingActiveLines,
                                     onEditLine,
    onRemoveOrderLine,
    viewOnly = false
                                 }) => {


    const handleEditLineClick = (line) => {
        onEditLine(line);
    };

    const removeOrderLine = (line) => {
        onRemoveOrderLine(line)
    }

    return (
        <div className={'mt-3'}>
            <Table hover bordered striped>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Код</th>
                    <th>Стока/Услуга</th>
                    <th>Мярка</th>
                    <th>Количество</th>
                    <th>Цена</th>
                    <th>Отстъпка</th>
                    <th>Сума</th>
                    <th></th>

                </tr>
                </thead>
                <tbody>
                {lines
                    .filter(orderLine => orderLine.isActive === showingActiveLines)
                    .map((orderLine, index) => (
                        <tr key={orderLine.id} className={isNaN(orderLine.id) ? 'temporary-order-line' : ''}>
                            <td>{index + 1}</td>
                            <td>{orderLine.article.skuNumber}</td>
                            <td>{orderLine.article.name}</td>
                            <td>{orderLine.article.quantityType.name}</td>
                            <td>{orderLine.quantity}</td>
                            <td>{toFloatBgn(orderLine.price)}</td>
                            <td>{orderLine.discount}%</td>
                            <td>{toFloatBgn(orderLine.totalPrice)}</td>
                            {showingActiveLines && !viewOnly ? (
                                <td>
                                    <Button className={'me-1'} variant="success"
                                            onClick={() => handleEditLineClick(orderLine)}>Редактирай</Button>
                                    <Button variant="danger" onClick={() => {
                                        removeOrderLine(orderLine)
                                    }}>Премахни</Button>
                                </td>
                            ) : <td></td>}
                        </tr>
                    ))}
                </tbody>
            </Table>

        </div>
    )
}

export default OrderLinesTableComponent;