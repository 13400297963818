import React from 'react';
import { Form } from 'react-bootstrap';

function FormInput({ label, name, value, onChange, errors = [], type = 'text', as= '' }) {
    return (
        <Form.Group className={'pt-2'}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                isInvalid={!!errors[name]}
                as={as ? as : 'input'}
                rows={as === 'textarea' ? 5 : undefined}
            />
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormInput;
