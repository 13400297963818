import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from './pages/Login';
import {checkAuth, fetchUser} from "./slices/authSlice.js";
import Sidebar from "./pages/Sidebar.js";
import Header from "./pages/Header.js";
import './App.css';
import {Container} from "react-bootstrap";
import ProtectedRoutes from "./ProtectedRoutes.js";

const App = () => {
    const dispatch = useDispatch();
    const {user, status} = useSelector((state) => state.auth);
    const isAuthenticated =  localStorage.getItem('isAuthenticated') === 'true';

    useEffect(() => {
        const checkAndFetchUser = async () => {
            await dispatch(fetchUser({}));
        };

        if (status === 'idle' && user === null && isAuthenticated) {
            checkAndFetchUser();
        }
    }, [status, dispatch, user, isAuthenticated]);

    if (status === 'loading') {
        return (
            <div>Loading...</div>
        );
    }

    return (
        <Router>
            <div className="app">
                {user ? <Sidebar user={user}/> : ''}
                <div className="main-content">
                    <Header/>
                    <Container fluid className={'p-3 ps-5'}>
                        {user ? (
                            <ProtectedRoutes user={user} />

                        ):
                            (
                                <Routes>
                                    <Route path="/" element={<Login />}/>
                                    {!isAuthenticated && (
                                        <Route path="*" element={<Navigate to="/" replace />} />
                                    )}
                                </Routes>
                            )}
                    </Container>
                </div>
            </div>
        </Router>
    );
};

export default App;