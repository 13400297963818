import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
};

export const fetchOrders = fetchItems('orders/fetchOrders', '/orders');

export const fetchArticleOrders = fetchItems(
    'orders/fetchArticleOrders', '/orders/article/[ID_PLACEHOLDER]'
);


const orderListSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchOrders)
        getCommonExtraReducersList(builder, fetchArticleOrders)
    },
});

export const {
    setCurrentPage
} = orderListSlice.actions;


export default orderListSlice.reducer;