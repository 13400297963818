import React from 'react';
import { Form } from 'react-bootstrap';

function FormNumericInput({
                              label ='',
                              name,
                              value,
                              onChange,
                              errors ='',
    step=1,
    min =0,
    max =null


                          }) {

    return (
        <Form.Group className={'mb-2'}>
            {label && (
            <Form.Label>{label}</Form.Label>
            )}
            <Form.Control
                name={name}
                value={value === null ? 0 : value}
                onChange={onChange}
                type={'number'}
                isInvalid={!!errors[name]}
                max={max}
                min={min}
                step={step}
            />
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormNumericInput;
