import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchArticle,
    resetStateData,
} from "../../../slices/articleSlice.js";
import {useParams} from "react-router-dom";
import {getVatFloatBgn, processIdInformation, toFloatBgn} from "../../../utils/functions.js";
import {Card} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import ArticleNavComponent from "./components/ArticleNavComponent.js";

const ArticleViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: article, loading} = useSelector((state) => state.article);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchArticle({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading && article.id;
    }

    const getTitle = () => {
        return `Артикул № ${id}`;
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            <ArticleNavComponent id={id} activeKey={'view'}/>
            {showPage() ? (
                <div>
                    <Card>
                        <Card.Body>
                            <Row>
                                <div>
                                    <div><b>Име: </b>{article.name}</div>
                                    <div><b>Код: </b>{article.skuNumber}</div>
                                    <div><b>Нов: </b>{article.isNew ? 'Да' : 'Не'}</div>
                                    <div><b>Прозиводител: </b>{article.manufacturer}</div>
                                    <div><b>Доставчик: </b>{article.supplier}</div>
                                    <div><b>Мерна единица: </b>{article.quantityType.name}</div>
                                    <div><b>Продажна цена: </b>{toFloatBgn(article.sellingPrice)} (<i>ДДС: {getVatFloatBgn(article.sellingPrice)}</i>)</div>
                                    <div><b>Покупна цена: </b>{toFloatBgn(article.costPrice)} (<i>ДДС: {getVatFloatBgn(article.costPrice)}</i>)</div>
                                    <div><b>На склад: </b>{article.stock} / <i>Резевирани: {article.reservedStock}</i></div>
                                    <div><b>Бележка: </b></div>
                                    <div>{article.additionalInformation}</div>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default ArticleViewPage;
