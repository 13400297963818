import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrder,
    resetStateData,
} from "../../../slices/orderSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import MultipleFileUploadComponent from "../../../components/MultipleFileUploadComponent.js";
import {Card} from "react-bootstrap";
import OrderNavComponent from "./components/OrderNavComponent.js";
import FileListComponent from "../../../components/FileListComponent.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";

const OrderFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: order, loading, errors: stateErrors, status} = useSelector((state) => state.order);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchOrder({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading;
    }

    const getTitle = () => {
        return idIsInteger ? `Поръчка № ${id}` : 'Нова поръчка';
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            <OrderNavComponent id={id} activeKey={'files'}/>
            {showPage() ? (
                <Card>
                    <Card.Header>
                        <MultipleFileUploadComponent
                            fetchItem={fetchOrder}
                            id={id}
                            itemsPath={'orders'}
                        />
                    </Card.Header>
                    <Card.Body>
                        <ErrorAlertComponent error={stateErrors}/>
                        <FileListComponent files={order.files} itemsPath={'orders'} id={id}
                                           onFileDelete={() => dispatch(fetchOrder({id: id}))}/>
                    </Card.Body>

                </Card>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};



export default OrderFilesPage;
