import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchClient, resetStateData, saveClient} from "../../../slices/clientSlice.js";
import {processIdInformation} from "../../../utils/functions.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";
import ClientForm from "./Components/ClientForm.js";
import {fetchClientTypes} from "../../../slices/clientTypeListSlice.js";
import {setErrorsFromValidation, getValidationSchema} from "../../../utils/validation.js";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";

const ClientPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);
    const dispatch = useDispatch();
    const {data: client, loading, errors: stateErrors, status} = useSelector((state) => state.client);
    const {data: clientTypes, loading: clientTypesLoading} = useSelector((state) => state.clientTypeList)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const clientSchema = getValidationSchema(
        {
            name: ['required'],
            email: ['required', 'email'],
            phoneNumber: ['required'],
            identificationId: ['required'],
            clientType: ['required', 'object'],
            address: ['required'],
            city: ['required'],
            country: ['required']
        }
    )

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchClient({id: id}));
        }
        dispatch(fetchClientTypes({}));
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/clients', {state: {message: 'Успешно запазен клиент'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (event) => {
        const attribute = event.target.name;
        const value = event.target.value;
        dispatchChangeAttribute(attribute, value);
    };

    const dispatchChangeAttribute = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    }

    const handleClientTypeChange = (e) => {
        const newClientType = clientTypes.find(clientType => clientType.id === parseInt(e.target.value));
        dispatchChangeAttribute('clientType', newClientType);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrors({});
            await clientSchema.validate(client, {abortEarly: false});
            await dispatch(saveClient({id: idIsInteger ? id : null, item: client}));
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const showForm = () => {
        return !loading && !clientTypesLoading;
    }

    const getTitle = () => {
        return idIsInteger ? `Клиент № ${id}` : 'Нов клиент';
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            {showForm() ? (
                <Card>
                    <Card.Body>
                        <ErrorAlertComponent error={stateErrors}/>
                        <ClientForm onClientTypeChange={handleClientTypeChange}
                                    onAttributeChange={handleAttributeChange}
                                    errors={errors}
                                    client={client}
                                    clientTypes={clientTypes}
                                    onSubmit={handleSubmit}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="success" type="submit" onClick={handleSubmit}>
                            Запази
                        </Button>
                    </Card.Footer>
                </Card>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default ClientPage;
