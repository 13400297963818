import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";

const SearchAutoCompleteComponent = ({
                                         onItemChange,
                                         item,
                                         name,
                                         fetchItems,
                                         errors,
                                         itemLabel = "name",
                                         reducer,
                                         label,
                                     }) => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const [focusedIndex, setFocusedIndex] = useState(-1); // Track the focused item
    const items = useSelector((state) => state[reducer].data);

    const listRef = useRef(null); // Ref for the <ul> element

    useEffect(() => {
        // Only fetch if the query length is greater than 1
        if (query.length > 1 && query) {
            const delayDebounceFn = setTimeout(() => {
                dispatch(fetchItems({ search: query }));
            }, 300);

            // Cleanup function to clear the timeout if query changes within the delay period
            return () => clearTimeout(delayDebounceFn);
        }
    }, [query, dispatch, fetchItems]);

    const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
            setQuery("");
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup the event listener when component unmounts
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleItemChange = (item) => {
        onItemChange(item);
        setQuery("");
        setFocusedIndex(-1); // Reset focused index
    };

    // Handle keydown events for arrow and enter key navigation
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            // Move focus down, but not beyond the list
            setFocusedIndex((prevIndex) =>
                prevIndex < items.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === "ArrowUp") {
            // Move focus up, but not before the first item
            setFocusedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : items.length - 1
            );
        } else if (e.key === "Enter" && focusedIndex !== -1) {
            e.preventDefault(); // Prevent form submission

            // Select the focused item when Enter is pressed
            handleItemChange(items[focusedIndex]);
            setFocusedIndex(-1); // Reset the focused index
        }
    };

    // Scroll the focused item into view
    useEffect(() => {
        if (listRef.current && focusedIndex !== -1) {
            const listItem = listRef.current.children[focusedIndex];
            if (listItem) {
                listItem.scrollIntoView({ block: "nearest" });
            }
        }
    }, [focusedIndex]);

    return (
        <div className={'position-relative pt-2'}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                autoComplete={'off'}
                type="text"
                placeholder="Търси..."
                value={query ? query : item ? item[itemLabel] : ''}
                isInvalid={!!errors[name]}
                onChange={(e) => {
                    setQuery(e.target.value);
                    setFocusedIndex(-1); // Reset focused index when typing
                }}
                onKeyDown={handleKeyDown} // Attach keydown event for navigation
            />
            {query && items.length > 0 && (
                <ul className={"search-auto-complete-results"} ref={listRef}>
                    {items.map((item, index) => (
                        <li
                            key={item.id}
                            onClick={() => handleItemChange(item)}
                            onMouseEnter={() => setFocusedIndex(index)} // Handle hover event
                            className={focusedIndex === index ? "focused" : ""}
                            style={{
                                backgroundColor: focusedIndex === index ? "#ddd" : "transparent",
                            }}
                        >
                            {item[itemLabel]}
                        </li>
                    ))}
                </ul>
            )}
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </div>
    );
};

export default SearchAutoCompleteComponent;
