import {Routes, Route} from "react-router-dom";
import ClientListPage from "../pages/Clients/List/ClientListPage.js";
import ClientPage from "../pages/Clients/Single/ClientPage.js";

const ClientRoutes = () => (
    <Routes>
        <Route index element={<ClientListPage />} />
        <Route path=':id' element={<ClientPage />} />
    </Routes>
);

export default ClientRoutes;