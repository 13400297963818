import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchArticles, setCurrentPage} from "../../../slices/articleListSlice.js";
import ButtonLink from "../../../components/Elements/ButtonLink.js";
import {Button, Table} from "react-bootstrap";
import PaginationComponent from "../../../components/PaginationComponent.js";
import SearchQueryInputComponent from "../../../components/SearchQueryInputComponent.js";
import {Link} from "react-router-dom";
import {toFloatBgn} from "../../../utils/functions.js";
import SuccessMessageComponent from "../../../components/SuccessMessageComponent.js";

const ArticleListPage = () => {
    const dispatch = useDispatch();
    const {data: articles, loading, pagination} = useSelector((state) => state.articleList);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(fetchArticles({page: pagination.currentPage, search: query}));
    }, [dispatch, pagination.currentPage, query]);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    return (
        <div>
            <SuccessMessageComponent />
            <h2>Артикули</h2>
            <div className="d-flex justify-content-end">
                <ButtonLink to={'/articles/create/edit'}>Добави</ButtonLink>
            </div>
            <SearchQueryInputComponent onQueryChange={setQuery} />
            {!loading && (
                <div className={'p-3'}>
                    <Table striped bordered hover>
                        <thead>
                        <tr className={'row'}>
                            <th className={'col-1'}>№</th>
                            <th className={'col-2'}>Име</th>
                            <th className={'col-2'}>Код</th>
                            <th className={'col-2'}>Прозиводител</th>
                            <th className={'col-1'}>Продажна цена</th>
                            <th className={'col-1'}>Покупна цена</th>
                            <th className={'col-1'}>Резервирана наличност</th>
                            <th className={'col-1'}>Наличност</th>
                            <th className="col-1"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {articles.map((article) => (
                            <tr key={article.id} className={'row'}>
                                <td className={'col-1'}>{article.id}</td>
                                <td className={'col-2'}>{article.name}</td>
                                <td className={'col-2'}>{article.skuNumber}{!article.isNew ? <i> (втора ръка)</i> : ''}</td>
                                <td className={'col-2'}>{article.manufacturer}</td>
                                <td className={'col-1'}>{toFloatBgn(article.sellingPrice)}</td>
                                <td className={'col-1'}>{toFloatBgn(article.costPrice)}</td>
                                <td className={'col-1'}>{article.reservedStock}</td>
                                <td className={'col-1'}>{article.stock}</td>
                                <td className={'col-1'}>
                                    <Button variant="link">
                                        <Link to={`/articles/${article.id}`}>Редактирай</Link>
                                    </Button>
                                    <Button variant="link">
                                        <Link to={`/articles/${article.id}/orders`}>Поръчки</Link>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

                </div>
            )}
        </div>
    );
};

export default ArticleListPage;
