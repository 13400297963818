import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
};
export const fetchArticles = fetchItems('articles/fetchArticles', '/articles');

const articleListSlice = createSlice({
    name: 'articles',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchArticles)
    },
});

export const {
    setCurrentPage
} = articleListSlice.actions;

export default articleListSlice.reducer;