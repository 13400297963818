import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCarManufacturers, setCurrentPage} from '../../../slices/carManufacturerListSlice.js';
import { Table} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ButtonLink from "../../../components/Elements/ButtonLink.js";
import SearchQueryInputComponent from "../../../components/SearchQueryInputComponent.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import SuccessMessageComponent from "../../../components/SuccessMessageComponent.js";

const CarManufacturerListPage = () => {
    const dispatch = useDispatch();
    const {data: carManufacturers, loading, pagination} = useSelector((state) => state.carManufacturerList);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(fetchCarManufacturers({page: pagination.currentPage, search: query}));
    }, [dispatch, pagination.currentPage, query]);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    return (
        <div>
            <SuccessMessageComponent />
            <h2>Прозиводители</h2>
            <div className="d-flex justify-content-end">
                <ButtonLink to="/car-manufacturers/create">Добави</ButtonLink>
            </div>
            <div>
                <SearchQueryInputComponent onQueryChange={setQuery}/>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th className={'col-1'}>№</th>
                    <th className={'col-2'}>Име</th>
                    <th className={'col-8'}>Синоними</th>
                    <th className={'col-1'}></th>
                </tr>
                </thead>
                {!loading && (

                    <tbody>
                    {carManufacturers.map((carManufacturer) => (
                        <tr key={carManufacturer.id}>
                            <td className={'col-1'}>{carManufacturer.id}</td>
                            <td className={'col-2'}>{carManufacturer.name}</td>
                            <td className={'col-8'}>{carManufacturer.synonyms}</td>
                            <td className={'col-1'}>
                                <Link to={`/car-manufacturers/${carManufacturer.id}`}>Редактирай</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                )}

            </Table>
            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </div>
    );
};

export default CarManufacturerListPage;
