import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchOrders, setCurrentPage} from "../../../slices/orderListSlice.js";
import OrderListTable from "./components/OrderListTable.js";
import ButtonLink from "../../../components/Elements/ButtonLink.js";
import SuccessMessageComponent from "../../../components/SuccessMessageComponent.js";
import SearchQueryInputComponent from "../../../components/SearchQueryInputComponent.js";
import PaginationComponent from "../../../components/PaginationComponent.js";

const OrderListPage = () => {
    const dispatch = useDispatch();
    const {data: orders, loading,pagination} = useSelector((state) => state.orderList);
    const [query, setQuery] = useState('');

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    useEffect(() => {
        dispatch(fetchOrders({page: pagination.currentPage, search: query}));
    }, [dispatch, pagination.currentPage, query]);


    return (
        <div>
            <SuccessMessageComponent />

            <h2>Orders</h2>
            <div className="d-flex justify-content-end">
                <ButtonLink to={'/orders/create/edit'}>Добави</ButtonLink>
            </div>
            <SearchQueryInputComponent onQueryChange={setQuery} />
            {!loading && (
                <OrderListTable orders={orders}/>
            )}

            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </div>
    );
};

export default OrderListPage;
