import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchClients, setCurrentPage} from "../../../slices/clientListSlice.js";
import {Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import ButtonLink from "../../../components/Elements/ButtonLink.js";
import SearchQueryInputComponent from "../../../components/SearchQueryInputComponent.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import SuccessMessageComponent from "../../../components/SuccessMessageComponent.js";

const ClientListPage = () => {
    const dispatch = useDispatch();
    const {data: clients, loading, pagination} = useSelector((state) => state.clientList);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(fetchClients({page: pagination.currentPage, search: query}));
    }, [dispatch, pagination.currentPage, query]);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    return (
        <div>
            <SuccessMessageComponent />
            <h2>Клиенти</h2>
            <div className="d-flex justify-content-end">
                <ButtonLink to={'/clients/create'}>Добави</ButtonLink>
            </div>
            <div>
                <SearchQueryInputComponent onQueryChange={setQuery} />
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Име</th>
                    <th>Email</th>
                    <th>Телефон</th>
                    <th>ЕГН/Булстат</th>
                    <th>Тип</th>
                </tr>
                </thead>
                { !loading && (

                    <tbody>
                {clients.map((client) => (
                    <tr key={client.id}>
                        <td>{client.id}</td>
                        <td>{client.name}</td>
                        <td>{client.email}</td>
                        <td>{client.phoneNumber}</td>
                        <td>{client.identificationId}</td>
                        <td>{client.clientType.name}</td>
                        <td>
                            <Button variant="link">
                                <Link to={`/clients/${client.id}`}>Редактирай</Link>
                            </Button>
                        </td>
                    </tr>
                ))}
                    </tbody>
                )}

            </Table>

            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </div>
    );
};

export default ClientListPage;
