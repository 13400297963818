import React, {useState} from "react";
import {Button, Image, Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ConfirmationModalComponent from "./ConfirmationModalComponent.js";
import {deleteFile} from "../slices/fileSlice.js";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";

const FileListComponent = ({
    files = [],
    itemsPath,
    id,
    onFileDelete
                           }) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [modalBodyText, setModalBodyText] = useState('');
    const path = `${process.env.REACT_APP_API_HOST_URL}/${itemsPath}/image/${id}`;
    const [imageToDelete, setImageToDelete] = useState('');
    const {loading: isDeleteLoading} = useSelector((state) => state.file);

    function separateFiles(fileNames) {
        const images = [];
        const otherFiles = [];

        fileNames.forEach(fileName => {
            const extension = fileName.split('.').pop().toLowerCase();
            if (['jpg', 'jpeg', 'png'].includes(extension)) {
                images.push(fileName);
            } else {
                otherFiles.push(fileName);
            }
        });

        return {images, otherFiles};
    }

    const {images, otherFiles} = separateFiles(files);

    const showConfirmationModal = (image) => {
        setImageToDelete(image)
        setModalBodyText(`Сигурен ли си, че искаш да изтриеш файл ${image}?`)
        setShowModal(true)
    }

    const handleDeleteImage = () => {
        dispatch(deleteFile({path: `${path}/${imageToDelete}`}));
        setShowModal(false);
        onFileDelete();
    }

    return (
        <>
            {images.length > 0 && (
                <Row>
                    <h5>Снимки</h5>
                    {images.map((image, index) => (
                        <Col key={index} md={3} className={'image-in-list-component-box'}>
                            <a href={`${path}/${image}`} target="_blank">
                                <Image key={index}
                                       src={`${path}/${image}`}/>
                            </a>
                            <Button className={'small-button'} variant={'danger'}
                                    onClick={() => showConfirmationModal(image)}>Изтрий</Button>

                        </Col>
                    ))}
                </Row>
            )}
            {otherFiles.length > 0 && (
                <Row className={'mt-2'}>
                    <h5>Документи</h5>
                    <Col md={6}>
                        <Table bordered>
                            <tbody>
                            {otherFiles.map((file, index) => (
                                <tr key={index}>
                                    <td>
                                        <a href={`${path}/${file}`} target="_blank" className={'me-2'}>
                                            {file}
                                        </a>
                                    </td>
                                    <td>
                                        <Button className={'small-button'} variant={'danger'}
                                                onClick={() => showConfirmationModal(file)}>Изтрий</Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            <ConfirmationModalComponent
                loading={isDeleteLoading}
                show={showModal}
                onDecline={() => setShowModal(false)}
                bodyText={modalBodyText}
                onConfirm={handleDeleteImage}
            />
        </>
    )
}

export default FileListComponent;