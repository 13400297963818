import {Routes, Route} from "react-router-dom";
import CarManufacturerListPage from "../pages/CarManufacturers/List/CarManufacturerListPage.js";
import CarManufacturerPage from "../pages/CarManufacturers/Single/CarManufacturerPage.js";

const CarManufacturerRoutes = () => (
    <Routes>
        <Route index element={<CarManufacturerListPage />} />
        <Route path=':id' element={<CarManufacturerPage />} />
    </Routes>
);

export default CarManufacturerRoutes;