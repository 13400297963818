import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchArticle,
    resetStateData,
} from "../../../slices/articleSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import MultipleFileUploadComponent from "../../../components/MultipleFileUploadComponent.js";
import {Card} from "react-bootstrap";
import ArticleNavComponent from "./components/ArticleNavComponent.js";
import FileListComponent from "../../../components/FileListComponent.js";
import ErrorAlertComponent from "../../../components/ErrorAlertComponent.js";

const ArticleFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: article, loading, errors: stateErrors} = useSelector((state) => state.article);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchArticle({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading;
    }

    const getTitle = () => {
        return  `Артикул № ${id}`;
    }

    return (
        <div>
            <h2>{getTitle()}</h2>
            <ArticleNavComponent id={id} activeKey={'files'}/>
            {showPage() ? (
                <Card>
                    <Card.Header>
                        <MultipleFileUploadComponent
                            fetchItem={fetchArticle}
                            id={id}
                            itemsPath={'articles'}
                        />
                    </Card.Header>
                    <Card.Body>
                        <ErrorAlertComponent error={stateErrors}/>
                        <FileListComponent files={article.files} itemsPath={'articles'} id={id}
                                           onFileDelete={() => dispatch(fetchArticle({id: id}))}/>
                    </Card.Body>

                </Card>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};



export default ArticleFilesPage;
