import {Routes, Route} from "react-router-dom";
import CarModelListPage from "../pages/CarModels/List/CarModelListPage.js";
import CarModelPage from "../pages/CarModels/Single/CarModelPage.js";

const CarModelRoutes = () => (
    <Routes>
        <Route index element={<CarModelListPage />} />
        <Route path=':id' element={<CarModelPage />} />
    </Routes>
);

export default CarModelRoutes;