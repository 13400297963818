import {createAsyncThunk} from "@reduxjs/toolkit";
import apiClient from "../axios.js";

export const fetchItems = (typePrefix, path) => {
    return createAsyncThunk(
        typePrefix,
        async ({search = '', page = 1, id } = {}) => {
            let modifiedPath = path;

            if (id) {
                modifiedPath = modifiedPath.replace('[ID_PLACEHOLDER]', id)
            }

            let queryParams = {};
            queryParams['page'] = page;
            if (search) {
                queryParams['search'] = search;
            }
            let queryParamsString = new URLSearchParams(queryParams).toString();
            modifiedPath += `?${queryParamsString}`;

            const response = await apiClient.get(modifiedPath);
            return response.data;
        });
}

export const deleteItem = (typePrefix) => {
    return createAsyncThunk(
        typePrefix,
        async ({path}) => {
            const response = await apiClient.delete(path);
            return response.data;
        }
    )
}

export const uploadItem = (typePrefix) => {
    return createAsyncThunk(
        typePrefix,
        async ({path, formData}, {rejectWithValue}) => {
            let response;
            try {
                response = await  apiClient.post(path, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                return response.data;
            } catch (error) {
                console.log(error.response.data)
                if (error.response.status === 500) {
                    return rejectWithValue(['Сървър грешка. Обърни се към IT.']);
                }
                if (error.response.data.error) {
                    return rejectWithValue([error.response.data.error]);
                }
                return rejectWithValue(error.response.data.errors);
            }
        }
    )
}

export const fetchItem = (typePrefix, path) => {
    return createAsyncThunk(
        typePrefix,
        async ({id}) => {
            let response;
            if (id && path.includes('[ID_PLACEHOLDER]')) {
                const updatedPath = path.replace('[ID_PLACEHOLDER]', id);
                response = await apiClient.get(updatedPath);
            } else if (id) {
                response = await apiClient.get(`${path}/${id}`);
            } else {
                response = await apiClient.get(path);
            }
            return response.data;
        });
}

export const saveItem = (typePrefix, path) => {

    return createAsyncThunk(
        typePrefix,
        async ({ id, item}, {rejectWithValue}) => {
            let response;
            try {
                if (id) {
                    response = await apiClient.put(`${path}/${id}`, item);
                } else {
                    response = await apiClient.post(`${path}`, item);
                }
                return await response.data;
            } catch (error) {
                if (error.response.status === 500) {
                    return rejectWithValue(['Сървър грешка. Обърни се към IT.']);
                }
                if (error.response.data.error) {
                    return rejectWithValue([error.response.data.error]);
                }
                return rejectWithValue(error.response.data.errors);
            }
        }
    );
}

