import {Pagination} from "react-bootstrap";
import React from "react";

const PaginationComponent = ({
    onPageChange,
    pagination
                             }) => {

    const handlePageChange = (page) => {
        onPageChange(page)
    }

    return (
        <div>
            <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={pagination.currentPage === 1}/>
                <Pagination.Prev
                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                    disabled={pagination.currentPage === 1}
                />
                {[...Array(pagination.lastPage)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                        <Pagination.Item
                            key={pageNumber}
                            active={pageNumber === pagination.currentPage}
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}
                <Pagination.Next
                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                    disabled={pagination.currentPage === pagination.lastPage}
                />
                <Pagination.Last onClick={() => handlePageChange(pagination.lastPage)}
                                 disabled={pagination.currentPage === pagination.lastPage}/>
            </Pagination>

            <p>Общо: {pagination.totalItems}</p>
        </div>
    )
};

export default PaginationComponent;