import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCarModels, setCurrentPage} from '../../../slices/carModelListSlice.js';
import {Table} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ButtonLink from "../../../components/Elements/ButtonLink.js";
import SearchQueryInputComponent from "../../../components/SearchQueryInputComponent.js";
import PaginationComponent from "../../../components/PaginationComponent.js";

const CarModelListPage = () => {
    const dispatch = useDispatch();
    const {data: carModels, loading, pagination} = useSelector((state) => state.carModelList);
    const [query, setQuery] = useState('');

    useEffect(() => {
        dispatch(fetchCarModels({page: pagination.currentPage, search: query}));
    }, [dispatch, pagination.currentPage, query]);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    return (
        <div>
            <h2>Модели</h2>
            <div className="d-flex justify-content-end">
                <ButtonLink to="/car-models/create">Добави</ButtonLink>
            </div>
            <div>
                <SearchQueryInputComponent onQueryChange={setQuery}/>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th className={'col-1'}>№</th>
                    <th className={'col-2'}>Име</th>
                    <th className={'col-2'}>Прозводител</th>
                    <th className={'col-6'}>Синоними</th>
                    <th className={'col-1'}></th>
                </tr>
                </thead>
                {!loading && (

                    <tbody>
                    {carModels.map((carModel) => (
                        <tr key={carModel.id}>
                            <td className={'col-1'}>{carModel.id}</td>
                            <td className={'col-2'}>{carModel.name}</td>
                            <td className={'col-2'}>{carModel.carManufacturer.name}</td>
                            <td className={'col-6'}>{carModel.synonyms}</td>
                            <td className={'col-1'}>
                                <Link to={`/car-models/${carModel.id}`}>Редактирай</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                )}

            </Table>
            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </div>
    );
};

export default CarModelListPage;
