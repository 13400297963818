import {useLocation} from "react-router-dom";
import {useState, useEffect} from "react";


const SuccessMessageComponent = () => {
    const location = useLocation();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (location.state && location.state.message) {
            setMessage(location.state.message);

            // Optionally, you can clear the message after a few seconds
            const timer = setTimeout(() => {
                setMessage(null);
            }, 3000); // Message disappears after 3 seconds

            return () => clearTimeout(timer);
        }
    }, [location]);

    return (
        <div>
            {message && <div className="alert alert-success">{message}</div>}
        </div>
    )
}

export default SuccessMessageComponent;