
export const changeAttributeReducer = (state, action) => {
    state.data[action.payload.attribute] = action.payload.value;
}

export const resetStateDataReducer = (initialState) => (state) => {
    return initialState;
};

export const setCurrentPageReducer = (state, action) => {
    state.pagination.currentPage = action.payload;
}